import accountStore from '@account/store'

export const GetMenuPermission = (menuId: number): survey.MenuPermissions => {
    
    let current = accountStore.state.current
    if(current){
        let surveyMenu = current.permissions.menues.filter(menu =>{
            return menu.id == menuId
        })

        if(surveyMenu.length > 0){
            return surveyMenu.map(item => {
                return {
                    Read: item.r,
                    Write: item.c,
                    Update: item.u,
                    Delete: item.d,
                    Print: item.p,
                    IsAdmin: item.manager,
                    MenuId : menuId
                }
            })[0]  
        }
    } 

    return {} as survey.MenuPermissions
}

 