import Vue from 'vue';
import { Component, Watch } from 'vue-property-decorator';
import { permissionHelper } from '@survey/util'
import { mapMutations } from 'vuex';
import * as types from '@survey/store/types'

@Component({
    methods: {
		...mapMutations({
			setSurveyPermissions : types.SURVEY_ADD_MENU_PERMISSIONS
		})
	},
})
export default class Survey extends Vue {
    setSurveyPermissions! : (permissions : survey.MenuPermissions) => void
    

    @Watch("$route")
    async onChangedRoute(){
        if(!this.$route.query.MENUID){
            await (window as any).alertAsync(this.$t('잘못된_접근입니다').toString())
            this.$router.push({
               // path : `${process.env.NODE_ENV === 'production' ? '/po/' : '/'}home`
                path : `/home`
            })
        }
    }

    @Watch("$route.query.MENUID")
    onChangedMenuId(){
        this.initPermissions()
    }
 
    //route watch보다 더빠름
    //근데 survey 내에서 이동할 때는 감지 못함
    async beforeCreate() {
        if(!this.$route.query.MENUID){
            await (window as any).alertAsync(this.$t('잘못된_접근입니다').toString())
            this.$router.push({
                // path : `${process.env.NODE_ENV === 'production' ? '/po/' : '/'}home`
                path : `/home`
            })
        }
    }

    created() {
        this.initPermissions()
    }


    initPermissions(){
         
        ///권한 관련 
        let permissions : survey.MenuPermissions 
        let menuId = parseInt(<string>this.$route.query.MENUID)

        permissions = permissionHelper.GetMenuPermission(menuId)
        this.setSurveyPermissions(permissions)
    }




 
}
